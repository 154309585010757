<template>
    <template-base>
        <div class="d-flex justify-content-between">
            <h1 class="align-self-center">Lista de detectores</h1>
            <div class="spacer"></div>
            <button class="btn btn-success text-white align-self-center" @click="downloadRelatorio"><DownloadIcon size="16" /> Relatório de detectores offline</button>
        </div>
        <DataTable scroll :async="pagination" :loading="loading" :colunas="cols" :linhas="linhas" :errMsg="errMsg" :state="dataTableState" @state-change="setStateDataTable">
            <template #[periodo]="{ value }">
                {{formatter(value[0])}} - {{formatter(value[1])}}
            </template>
            <template #results-page v-if="pagination">
                <select class="custom-select ml-1" :value="results" @input="changeResults" data-cy="resultados-pagina" >
                    <option value="10">10 por página</option>
                    <option value="20">20 por página</option>
                    <option value="50">50 por página</option>
                    <option value="100">100 por página</option>
                </select>
            </template>
        </DataTable>
        <Paginator :page="page" :pages="pages" :count="count" :disabled="loading" :first="first" :last="last" @paginate="paginate" />
    </template-base>
</template>

<style scoped>
    .custom-select {
        width: auto;
    }
    .paginador {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
import DataTable from '@/components/DataTable'
import TemplateBase from '@/templates/Base'
import Paginator from '@/components/Paginator'
import loginService from '@/services/login'
import axios from 'axios'
import api from '@/api'

export default {
    name: 'GenericList',
    components: {
        DataTable,
        TemplateBase,
        Paginator,
    },
    data () {
        return {
            cols: ['#','Nome', 'SAP', 'Descrição', 'Canal', 'Concentrador', 'Range', 'Unidade', 'Alarme', 'Alerta', 'Tipo de gás', 'Tag de Valor', 'Tag de Alerta', 'Tag de Alarme', 'Elemento','Período de calibração'],
            colsName: ['id', 'nome', 'sap', 'descricao','canal','idConcentrador', 'range', 'unidade', 'alarme', 'alerta', 'tipoGas', 'tagValor', 'tagAlerta', 'tagAlarme', 'elemento', 'dataInicioCalibracao'],
            lista: [],
            loading: false,
            errMsg: '',
            pagination: false,
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
            periodo: 'Período de calibração'
        };
    },
    computed: {
        dataTableState () {
            return this.$store.state.dataTables['dataTableDetector'];
        },
        linhas () {
            return this.lista.map(detector => ({
                href: { name: 'edit_concentrador' , params: { id: detector.idConcentrador } },
                cols: [detector.id, detector.nome, detector.sap, detector.descricao, detector.canal, detector.concentrador.nome, detector.range, detector.unidade, detector.alarme, detector.alerta, detector.tipoGas, detector.tagValor, detector.tagAlerta, detector.tagAlarme, detector.elemento, [detector.dataInicioCalibracao, detector.dataFimCalibracao]],
            }));
        },
    },
    methods: {
        formatter(data) {
            return data.split('-').reverse().join('/');
        },
        downloadRelatorio() {
            let a = document.createElement('a');
            a.href = `${api.v1.relatorios.detectoresOffline}?bearer=${loginService.getBearerToken()}`;
            a.target = '_blank';
            a.click();
        },
        setStateDataTable (...args) {
            this.$store.commit('dataTables/setStateDataTableDetector', ...args);
            this.page = 0;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },
        update (page = 1, results = 20) {
            this.loading = true;
            let url;
            this.pagination = true;
            url = api.v1.detector.list(page, results, this.dataTableState.query, this.colsName[this.dataTableState.sortBy], this.dataTableState.sortAsc);

            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = res.data.rows || res.data;
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason.response.data ? reason.response.data.error : reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
    },
    created () {
        this.update();
    }
}
</script>
